import React, { useEffect, useContext } from "react";
import { Button, Input, Space, Form, Alert, Spin } from "antd";
import { LanguageContext } from "../App";
import { locales } from "../locales";

import WordTag from "../components/WordTag";
const { TextArea } = Input;

const Process = () => {
  const url = "https://process-polish-api-webapp.azurewebsites.net";

  const [result, setResult] = React.useState();
  const language = useContext(LanguageContext);
  const [error, setError] = React.useState();
  const [isLoading, setLoading] = React.useState(false);

  const onFinish = (values) => {
    setResult(null);
    setError(null);
    setLoading(true);
    const text = values.text;
    const body = JSON.stringify({ text, language });
    fetch(`${url}/process/`, { method: "POST", body })
      .then((res) => {
        if (!res.ok) {
          throw Error("Could not fetch");
        }
        return res.json();
      })
      .then((data) => {
        setResult(data);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Clear the results when changing language
    setResult();
  }, [language]);

  return (
    <div style={{ width: "95%", maxWidth: "900px" }}>
      <Form onFinish={onFinish}>
        <Form.Item
          name="text"
          rules={[
            {
              required: true,
              message: locales[language].processTextValidation,
            },
          ]}
        >
          <TextArea
            maxLength={1000}
            placeholder={locales[language].processTextPlaceholder}
            style={{ textAlign: "center" }}
          />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" size="small">
            {locales[language].processButtonLabel}
          </Button>
        </Form.Item>
      </Form>
      {error && <Alert className="Alert" message={error} type="error" />}
      {isLoading && <Spin size="small" />}
      {result && (
        <Space wrap>
          {result.tokens.map((token, index) => {
            return (
              <WordTag
                key={index}
                word={token.text}
                data={token.data}
                pos={token.pos_tag}
                is_oov={token.is_oov}
              />
            );
          })}
        </Space>
      )}
    </div>
  );
};

export default Process;
