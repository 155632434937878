export const locales = {
  en: {
    processButtonLabel: "Analyse",
    conjugateButtonLabel: "Submit",
    menuHomeItemLabel: "Home",
    menuProcessItemLabel: "Analyse",
    menuConjugateItemLabel: "Verbs",
    menuNounsItemLabel: "Nouns",
    menuChatItemLabel: "Chat",
    menuAboutItemLabel: "About",
    processTextValidation: "Please enter text",
    processTextPlaceholder: "Add polish text here",
    conjugateVerbValidation: "Please enter an infinitive verb",
    conjugateVerbPlaceholder: "Select an infinitive",
    nounPlaceholder: "Select a noun",
    nounButtonLabel: "Submit",
    nounValidation: "Please enter a noun",
    chatScenarioPlaceholder: "Please select a scenario",
    chatScenarioButtonLabel: "Start",
    homeWelcomeText: "Welcome to Process Polish!",
    homeDescriptionText:
      "Process Polish is a set of tools for those studying polish language",
    homeConjugateDescriptionText:
      "Polish verb conjugation. Information about polish verbs",
    homeNounsDescriptionText:
      "Polish noun case endings. Information about polish nouns",
    homeProcessDescriptionText: "Pipeline analysis of polish text",
    homeChatDescriptionText: "Choose a scenario and chat with an AI",
    startTypingText: "Start typing to find words",
  },
  pl: {
    processButtonLabel: "Analizuj",
    conjugateButtonLabel: "Zatwierdź",
    menuHomeItemLabel: "Strona Główna",
    menuProcessItemLabel: "Analiza",
    menuConjugateItemLabel: "Czasowniki",
    menuNounsItemLabel: "Rzeczowniki",
    menuChatItemLabel: "Czat",
    menuAboutItemLabel: "O Stronie",
    processTextValidation: "Proszę wpisać tekst",
    processTextPlaceholder: "Wpisz tu polski tekst",
    conjugateVerbValidation: "Proszę wpisać bezokolicznik",
    conjugateVerbPlaceholder: "Wybierz bezokolicznik",
    nounPlaceholder: "Wybierz rzeczownik",
    nounButtonLabel: "Zatwierdź",
    nounValidation: "Proszę wpisać rzeczownik",
    chatScenarioPlaceholder: "Wybierz scenariusz",
    chatScenarioButtonLabel: "Rozpocznij",
    homeWelcomeText: "Witaj na Process Polish!",
    homeDescriptionText:
      "Process Polish jest zestawem narzędzi dla osób uczących się języka polskiego.",
    homeConjugateDescriptionText:
      "Koniugacja czasownika polskiego. Informacje o czasownikach polskich",
    homeNounsDescriptionText:
      "Polskie końcówki rzeczowników. Informacje o rzeczownikach polskich",
    homeProcessDescriptionText: "Analiza tekstu polskiego",
    homeChatDescriptionText:
      "Wybierz scenariusz i porozmawiaj ze sztuczną inteligencją",
    startTypingText: "Zacznij pisać, aby znaleźć słowa",
  },
  uk: {
    processButtonLabel: "аналізувати",
    conjugateButtonLabel: "Надіслати",
    menuHomeItemLabel: "Головна сторінка",
    menuProcessItemLabel: "аналізувати",
    menuConjugateItemLabel: "Дієслова",
    menuChatItemLabel: "чат",
    menuNounsItemLabel: "Іменники",
    menuAboutItemLabel: "Про сторінку",
    processTextValidation: "будь ласка, введіть текст",
    processTextPlaceholder: "Введіть польський текст тут",
    conjugateVerbValidation: "Будь ласка, введіть інфінітивне дієслово",
    conjugateVerbPlaceholder: "Виберіть інфінітив",
    nounPlaceholder: "Виберіть іменник",
    nounButtonLabel: "Надіслати",
    nounValidation: "Будь ласка, введіть іменник",
    chatScenarioPlaceholder: "Виберіть сценарій",
    chatScenarioButtonLabel: "старт",
    homeWelcomeText: "Ласкаво просимо до Process Polish!",
    homeDescriptionText:
      "Process Polish це набір інструментів для людей, які вивчають польську мову.",
    homeConjugateDescriptionText:
      "Польське дієвідмінювання. Інформація про польські дієслова",
    homeNounsDescriptionText:
      "Польські відмінкові закінчення іменників. Інформація про польські іменники",
    homeProcessDescriptionText: "Aналіз польського тексту",
    homeChatDescriptionText:
      "Виберіть сценарій і поспілкуйтеся зі штучним інтелектом",
    startTypingText: "Почніть друкувати, щоб знайти слова",
  },
};
