import "./App.css";
import React, { createContext, useState } from "react";
import { Layout, Radio, ConfigProvider, theme } from "antd";
import { ReactComponent as ReactLogo } from "./logo.svg";
import { Outlet } from "react-router-dom";
import { Footer } from "antd/es/layout/layout";
import NavBar from "./NavBar";
import CookieConsent from "react-cookie-consent";

const { Header, Content } = Layout;

const { darkAlgorithm, compactAlgorithm } = theme;

export const LanguageContext = createContext("en");

const App = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("pp_language") || "en",
  );

  const updateLanguage = (newLanguage) => {
    localStorage.setItem("pp_language", newLanguage);
    setLanguage(newLanguage);
  };

  return (
    <ConfigProvider
      theme={{
        algorithm: [darkAlgorithm, compactAlgorithm],
        token: {
          colorBgBase: "#1f202e",
          colorPrimary: "#86afb3",
          colorLink: "#86afb3",
        },
      }}
    >
      <div className="App">
        <CookieConsent>
          This website uses local storage to save your language preference
        </CookieConsent>
        <Layout>
          <Header style={{ background: "none", paddingInline: "0px" }}>
            <NavBar language={language} />

            <Radio.Group
              defaultValue={language}
              size="small"
              onChange={(e) => updateLanguage(e.target.value)}
            >
              <Radio.Button value="en">EN</Radio.Button>
              <Radio.Button value="pl">PL</Radio.Button>
              <Radio.Button value="uk">UKR</Radio.Button>
            </Radio.Group>
          </Header>
          <Content>
            <div className="App-header">
              <ReactLogo />
              <LanguageContext.Provider value={language}>
                <Outlet />
              </LanguageContext.Provider>
            </div>
          </Content>
          <Footer>Created by Process Polish</Footer>
        </Layout>
      </div>
    </ConfigProvider>
  );
};

export default App;
