import { Button, Tag, Popover, List, Typography, Spin, Space } from "antd";
import {
  SoundOutlined,
  CaretUpOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import { useState, useContext } from "react";
import { LanguageContext } from "../App";

const posColors = {
  ADJ: "magenta",
  ADP: "red",
  ADV: "volcano",
  AUX: "lime",
  CCONJ: "gold",
  DET: "orange",
  INTJ: "green",
  NOUN: "cyan",
  NUM: "red",
  PART: "orange",
  PRON: "purple",
  PROPN: "cyan",
  PUNCT: "#1f202e",
  SCONJ: "cyan",
  SYM: "purple",
  VERB: "lime",
  X: "purple",
};

const speaker = new SpeechSynthesisUtterance();
speaker.lang = "pl-PL";

const WordTag = ({ word, data, pos, is_oov = false }) => {
  const [translation, setTranslation] = useState();
  const [loadingTranslation, setLoadingTranslation] = useState();
  const language = useContext(LanguageContext);

  const url = "https://process-polish-api-webapp.azurewebsites.net";

  const play = (text) => {
    speaker.text = text;
    window.speechSynthesis.speak(speaker);
  };

  const translate = () => {
    setLoadingTranslation(true);
    fetch(`${url}/translate-word/`, {
      method: "POST",
      body: JSON.stringify({
        word: word,
        language: language,
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw Error();
        }
        return res.json();
      })
      .then((data) => {
        setTranslation(data.translation);
        setLoadingTranslation(false);
      })
      .catch((err) => {
        setLoadingTranslation(false);
      });
  };

  const content = (
    <>
      <Space direction="vertical">
        <Space direction="horizontal">
          <Button
            size="small"
            onClick={() => play(word)}
            icon={<SoundOutlined />}
          />
          <Button size="small" icon={<GlobalOutlined />}>
            <a
              href={`https://pl.wiktionary.org/wiki/${word.toLowerCase()}`}
              target="_blank"
              rel="norefferer noreferrer"
            >
              Wiki (PL)
            </a>
          </Button>
        </Space>
        {loadingTranslation ? <Spin size="small" /> : <i>{translation}</i>}
        {data && (
          <List
            dataSource={data}
            renderItem={(item) => {
              return (
                item.value && (
                  <List.Item key={item.key} style={{ padding: "0px" }}>
                    <Typography.Text strong>{item.label}</Typography.Text>{" "}
                    {item.value}
                  </List.Item>
                )
              );
            }}
          />
        )}
      </Space>
    </>
  );

  return (
    <Popover content={content} trigger="click">
      <Tag
        className="Tag-token"
        bordered={true}
        color={is_oov ? "#700b03" : posColors[pos]}
        onClick={translate}
        icon={
          <CaretUpOutlined
            style={{
              fontSize: "10px",
              verticalAlign: "top",
              marginTop: "10px",
            }}
          />
        }
      >
        {word}
      </Tag>
    </Popover>
  );
};

export default WordTag;
