import { MessageOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Space, Button } from "antd";
import { SoundOutlined, EditOutlined } from "@ant-design/icons";

const speaker = new SpeechSynthesisUtterance();
speaker.lang = "pl-PL";

const play = (text) => {
  speaker.text = text;
  window.speechSynthesis.speak(speaker);
};

const ChatMessage = ({ author, message }) => {
  if (author === "correction") {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Space direction="horizontal">
          <div
            style={{ fontSize: "10px", textAlign: "justify", color: "#ccf5c9" }}
          >
            {message}
          </div>
          <Avatar size="small" icon={<EditOutlined />} />
        </Space>
      </div>
    );
  }
  if (author === "ai") {
    return (
      <div style={{ display: "flex" }}>
        <Space direction="horizontal">
          <Avatar icon={<MessageOutlined />} />
          <div style={{ textAlign: "justify", color: "#f0d7f5" }}>
            {message}
          </div>
          <Button
            size="small"
            onClick={() => play(message)}
            icon={<SoundOutlined />}
          />
        </Space>
      </div>
    );
  }
  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Space direction="horizontal">
        <div style={{ textAlign: "justify", color: "#a4c8ed" }}>{message}</div>
        <Avatar icon={<UserOutlined />} />
      </Space>
    </div>
  );
};

export default ChatMessage;
