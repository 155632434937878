import React, { useContext, useEffect } from "react";
import {
  Button,
  Space,
  Form,
  Alert,
  Select,
  Checkbox,
  Spin,
  Empty,
} from "antd";
import VerbFormCard from "../components/VerbFormCard";
import WordTag from "../components/WordTag";

import { LanguageContext } from "../App";

import { locales } from "../locales";

const Verbs = () => {
  const url = "https://process-polish-api-webapp.azurewebsites.net";

  const [result, setResult] = React.useState();
  const [resultLoading, setResultLoading] = React.useState(false);
  const language = useContext(LanguageContext);
  const [error, setError] = React.useState();
  const [options, setOptions] = React.useState([]);
  const [searchText, setSearchText] = React.useState();

  useEffect(() => {
    if (!searchText) {
      setOptions([]);
      return;
    }
    if (searchText.length < 2) {
      setOptions([]);
      return;
    }

    const getData = setTimeout(() => {
      fetch(`${url}/verbs/?` + new URLSearchParams({ query: searchText }))
        .then((res) => {
          if (!res.ok) {
            throw Error("failed to fetch verbs");
          }
          return res.json();
        })
        .then((data) => {
          const options = data.words.filter((s) => s.startsWith(searchText));
          const options_obj = options.map((option) => {
            return { value: option };
          });
          setOptions(options_obj);
        })
        .catch((err) => {
          setError(err.message);
        });
    }, 1000);
    return () => clearTimeout(getData);
  }, [searchText]);

  const onFinish = (values) => {
    setResultLoading(true);
    setResult(null);
    setError(null);
    const verb = values.verb.trim().toLowerCase();
    const reflexive = values.reflexive || false;
    const body = JSON.stringify({ verb, language, reflexive });
    fetch(`${url}/verb/`, { method: "POST", body })
      .then((res) => {
        if (!res.ok) {
          throw Error();
        }
        return res.json();
      })
      .then((data) => {
        setResult(data);
      })
      .catch((err) => {
        setError(err.message);
      })
      .finally(() => {
        setResultLoading(false);
      });
  };

  useEffect(() => {
    // Clear the results when changing language
    setResult();
    setError();
    setOptions();
  }, [language]);

  return (
    <div style={{ width: "95%", maxWidth: "900px" }}>
      <Form onFinish={onFinish}>
        <Form.Item
          name="verb"
          rules={[
            {
              required: true,
              message: locales[language].conjugateVerbValidation,
            },
          ]}
        >
          <Select
            showSearch
            options={options}
            placeholder={locales[language].conjugateVerbPlaceholder}
            onSearch={(text) => setSearchText(text)}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={locales[language].startTypingText}
              />
            }
          />
        </Form.Item>
        <Form.Item name="reflexive" valuePropName="checked">
          <Checkbox>+ się</Checkbox>
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" size="small">
            {locales[language].conjugateButtonLabel}
          </Button>
        </Form.Item>
      </Form>
      {resultLoading && <Spin />}
      {error && <Alert className="Alert" message={error} type="error" />}
      {result && (
        <Space direction="vertical" style={{ display: "flex" }}>
          <WordTag word={result.text} pos="VERB" data={result.data} />
          {result.tenses.map((tense, index) => {
            return (
              <VerbFormCard
                key={index}
                title={tense.tense_label}
                data={tense.verbs}
              ></VerbFormCard>
            );
          })}
        </Space>
      )}
    </div>
  );
};

export default Verbs;
