import React, { useContext, useState, useEffect } from "react";
import { Button, Form, Select, Space } from "antd";

import { LanguageContext } from "../App";
import { ReloadOutlined } from "@ant-design/icons";

import { locales } from "../locales";
import ChatDialog from "../components/ChatDialog";

const scenario_locales = {
  coffee_shop: {
    en: "At the coffee shop",
    pl: "W kawiarni",
    uk: "У кав'ярні",
  },
  cinema: {
    en: "At the cinema",
    pl: "W kinie",
    uk: "У кінотеатрі",
  },
  hotel: {
    en: "At the hotel",
    pl: "W hotelu",
    uk: "В готелі",
  },
};

const Chat = () => {
  const url = "https://process-polish-api-webapp.azurewebsites.net";

  const language = useContext(LanguageContext);

  const [form] = Form.useForm();

  const [isInChat, setIsInChat] = useState(false);
  const [scenario, setScenario] = useState("");
  const [messages, setMessages] = useState([]);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isReplyLoading, setIsReplyLoading] = useState(false);

  const onChatReplyFinish = (values) => {
    setMessages([...messages, { author: "user", message: values.reply }]);
    setIsReplyLoading(true);

    fetch(`${url}/chat/`, {
      method: "POST",
      body: JSON.stringify({
        scenario: scenario,
        message: values.reply,
        prev_msgs: messages.filter((item) =>
          ["ai", "user"].includes(item.author),
        ),
      }),
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.text);
        }
        return res.json();
      })
      .then((data) => {
        if (!data.is_correct) {
          setMessages((previousMessages) => [
            ...previousMessages,
            { author: "correction", message: data.corrected },
          ]);
        }
        setMessages((previousMessages) => [
          ...previousMessages,
          { author: "ai", message: data.message },
        ]);
      })
      .catch((err) => {})
      .finally(() => {
        setIsReplyLoading(false);
      });
  };

  const onStartChatFinish = (values) => {
    setScenario(values.scenario);
    setIsInChat(true);
    setIsChatLoading(true);

    const scenario = values.scenario;
    const body = JSON.stringify({ scenario });

    fetch(`${url}/start-chat/`, {
      method: "POST",
      body,
    })
      .then((res) => {
        if (!res.ok) {
          throw Error(res.text);
        }
        return res.json();
      })
      .then((data) => {
        setMessages(messages.concat([{ author: "ai", message: data.message }]));
      })
      .catch((err) => {})
      .finally(() => {
        setIsChatLoading(false);
      });
  };

  useEffect(() => {
    form.resetFields(["scenario"]);
    setIsInChat(false);
    setMessages([]);
  }, [language, form]);

  useEffect(() => {}, [scenario]);

  return (
    <div style={{ width: "95%", maxWidth: "900px" }}>
      <Space direction="vertical" style={{ display: "flex" }}>
        <Form form={form} onFinish={onStartChatFinish}>
          <Form.Item name="scenario" rules={[{ required: true }]}>
            <Select
              options={[
                {
                  label: scenario_locales["coffee_shop"][language],
                  value: "coffee_shop",
                },
                {
                  label: scenario_locales["cinema"][language],
                  value: "cinema",
                },
                { label: scenario_locales["hotel"][language], value: "hotel" },
              ]}
              placeholder={locales[language].chatScenarioPlaceholder}
              disabled={isInChat}
            />
          </Form.Item>
          {!isInChat && (
            <Form.Item>
              <Button htmlType="submit" size="small">
                {locales[language].chatScenarioButtonLabel}
              </Button>
            </Form.Item>
          )}
          {isInChat && (
            <Button
              size="small"
              onClick={() => {
                form.resetFields(["scenario"]);
                setIsInChat(false);
                setMessages([]);
              }}
              icon={<ReloadOutlined />}
            />
          )}
        </Form>

        {isInChat && (
          <ChatDialog
            messages={messages}
            onChatReply={onChatReplyFinish}
            isLoading={isChatLoading}
            isReplyLoading={isReplyLoading}
          />
        )}
      </Space>
    </div>
  );
};

export default Chat;
