import { useEffect, useState } from "react";
import { Menu } from "antd";
import { Link, useLocation } from "react-router-dom";
import { locales } from "./locales";

const NavBar = ({ language }) => {
  const location = useLocation();
  const [selectedKey, setSelectedKey] = useState(["home"]);

  useEffect(() => {
    setSelectedKey(items.find((_item) => location.pathname === _item.path).key);
    // eslint-disable-next-line
  }, [location]);

  const items = [
    {
      label: <Link to="/">{locales[language].menuHomeItemLabel}</Link>,
      key: "home",
      path: "/",
    },
    {
      label: <Link to="verbs">{locales[language].menuConjugateItemLabel}</Link>,
      key: "verbs",
      path: "/verbs",
    },
    {
      label: <Link to="nouns">{locales[language].menuNounsItemLabel}</Link>,
      key: "nouns",
      path: "/nouns",
    },
    {
      label: <Link to="process">{locales[language].menuProcessItemLabel}</Link>,
      key: "process",
      path: "/process",
    },
    {
      label: <Link to="chat">{locales[language].menuChatItemLabel}</Link>,
      key: "chat",
      path: "/chat",
    },
    {
      label: <Link to="about">{locales[language].menuAboutItemLabel}</Link>,
      key: "about",
      path: "/about",
    },
  ];

  return (
    <Menu
      selectedKeys={[selectedKey]}
      style={{ justifyContent: "center", width: "100%" }}
      mode="horizontal"
      items={items}
    />
  );
};

export default NavBar;
