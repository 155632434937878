import React, { useState } from "react";
import { Space, Card, Form, Input, Button } from "antd";
import ChatMessage from "./ChatMessage";
import { LoadingOutlined, SendOutlined } from "@ant-design/icons";

const allowedMessages = 5;

const ChatDialog = ({ messages, onChatReply, isLoading, isReplyLoading }) => {
  const [form] = Form.useForm();
  const [messagesSent, setMessagesSent] = useState(0);

  const onSubmit = (values) => {
    onChatReply(values);
    setMessagesSent(messagesSent + 1);
    form.resetFields();
  };

  return (
    <Card loading={isLoading} style={{ height: "400px" }}>
      <Space
        direction="vertical"
        style={{
          display: "flex",
          height: "350px",
          overflowY: "auto",
          flexDirection: "column-reverse",
        }}
      >
        <div class="snap-to-bottom">
          {messages.map((message) => (
            <ChatMessage author={message.author} message={message.message} s />
          ))}
        </div>
      </Space>

      <Form
        form={form}
        layout="inline"
        onFinish={onSubmit}
        style={{ display: "flex", width: "100%" }}
        autoComplete="off"
      >
        <Form.Item name="reply" style={{ flex: 1 }}>
          <Input
            disabled={isReplyLoading || messagesSent >= allowedMessages}
            placeholder={`${messagesSent}/${allowedMessages}`}
          />
        </Form.Item>
        <Form.Item>
          <Button
            disabled={isReplyLoading}
            htmlType="submit"
            icon={isReplyLoading ? <LoadingOutlined /> : <SendOutlined />}
          ></Button>
        </Form.Item>
      </Form>
    </Card>
  );
};

export default ChatDialog;
